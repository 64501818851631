import React from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'
import font from "../../fonts/fonts.module.scss"
import { StaticImage } from "gatsby-plugin-image"

import Logo from "../../images/logo_branco.svg"
import OP from "../../images/openday/op-hr.png"
import IM from "../../images/openday/op-im.png"

export const NewsletterModal = ({ setIsOpen }) => {
  return (
    <>
      <StyledModal>
        <div className='left'>
          <div className='logo'>
            <img src={Logo} alt="carreira" className="logo" />
          </div>
          <div className={font.rSB + " close"} onClick={() => setIsOpen(false)}>
            X
          </div>
        </div>

        <div className={font.rSB + " container"}>
          <div>
            <h2>Próximos Eventos</h2>
          </div>
          
          <div>
            <Link to='/openday/implantologia'>
              <img src={IM} alt="carreira" className='image' />
            </Link>
          </div>

        </div>
      </StyledModal>

      <StyledOverlay onClick={() => setIsOpen(false)} />
    </>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  max-width: 25vw;
  border-radius: 0;
  opacity: 0;
  z-index: 111;
  box-shadow: 5px 4px 6px rgb(0 0 0 / 16%);
  overflow: hidden;
  animation: 0.3s ${fadeIn} ease forwards;
  padding: 1%;
  background-image: url("https://res.cloudinary.com/dq66bwgke/image/upload/v1678980804/images/POPUP-BG-03_ecv6ca.png");
  background-size: cover;

  @media only screen and (max-width: 700px) {
    padding: 5%;
  }

  .left {
    display: flex;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;

    .logo-btn {
      .logo {
        width: 50%;

        
      }
    }
  
    .close {
      color: #fff;
      cursor: pointer;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    overflow: hidden;

    @media only screen and (max-width: 700px) {
      margin-top: 3rem;
    }

    h2 {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 3rem;
      font-size: 0.9rem;
    }

    .image {
      width: 100%;
      margin-bottom: 2%;
    }
  }

  

  
  @media screen and (max-width: 1000px) {
    max-width: 90%;
    width: 90%;
    min-height: 90vw;
    .close{
      font-size: 1.5rem;
      z-index: 999;
    }
  }
`

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
`