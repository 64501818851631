import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import HomeScreen from "../components/homeScreen/indexHome"
import HomeScreenMobile from "../components/homeScreen/indexHomeMobile"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Destaque from "../components/destaque/destaque"
import DestaqueMobile from "../components/destaque/destaqueMobile"
import Tratamentos from "../components/destaque/tratamentos"
import TratamentosMobile from "../components/destaque/tratamentosMobile"
import Banner from "../components/banner/banner"
import BannerMobile from "../components/banner/bannerMobile"
import ClinicSlider from "../components/sliders/indexClinicSlider"
import CCSlider from "../components/sliders/ccslider"
import CCSliderMobile from "../components/sliders/ccsliderMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"
import { NewsletterModal } from "../components/openday/popup"


const IndexPage = ({ data }) => {
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)
  const showModal = () => setShowNewsletterModal(true)


  useEffect(() => {
    setTimeout(showModal, 5000)
    return () => clearTimeout(showModal)
  }, [])

  return (
    <>
      <SEO titleTemplate="%s" title="Carreira Dental Clinic" />
      <Layout home mobile={useBreakpoint().mobile}>
        {useBreakpoint().mobile ? (
          <>
            {/* popup openday 
          {showNewsletterModal && (
            <NewsletterModal
              isOpen={showNewsletterModal}
              setIsOpen={setShowNewsletterModal}
              data={data.opendayJson.banner.popup}
            />
          )}
          */}
            <NavbarMobile color="white" />
            <HomeScreenMobile data={data.indexJson.homescreen} />
            <DestaqueMobile data={data.indexJson.destaque} />
            <TratamentosMobile data={data.indexJson.tratamentosDestaque} />
            <BannerMobile data={data.indexJson.banner} />
            <ClinicSlider data={data.indexJson.bannerClinicas} />
            <CCSliderMobile data={data.indexJson.ccSlider} />
            <FooterMobile data={data.globalJson.footer} />
          </>
        ) : (
          <>
            {/* popup openday 
          {showNewsletterModal && (
            <NewsletterModal
              isOpen={showNewsletterModal}
              setIsOpen={setShowNewsletterModal}
              data={data.opendayJson.banner.popup}
            />
          )}
          */}
            <Navbar color="white" />
            <HomeScreen data={data.indexJson.homescreen} isAnimated />
            <Destaque data={data.indexJson.destaque} />
            <Tratamentos data={data.indexJson.tratamentosDestaque} />
            <Banner data={data.indexJson.banner} />
            <ClinicSlider data={data.indexJson.bannerClinicas} />
            <CCSlider data={data.indexJson.ccSlider} />
            <Footer data={data.globalJson.footer} />
          </>
        )}
      </Layout>
    </>
  )
}

export default IndexPage

export const Json = graphql`
  query index {
    opendayJson {
      banner {
        popup {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    indexJson {
      homescreen {
        videoLow {
          publicURL
        }
        poster {
          publicURL
        }
        posterMobile {
          publicURL
        }
      }
      destaque {
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
        imgsecond{
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altsecond
      }
      tratamentosDestaque {
        nome
        link
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
        detalhes
      }
      banner {
        imgs {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 5000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          legenda
        }
        imgMobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 5000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bg {
          publicURL
        }
      }
      bannerClinicas {
        bg {
          publicURL
        }
        imgs {
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 5000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          local
        }
      }
      ccSlider {
        title
        desc
        img {
          publicURL
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        video {
          publicURL
        }
      }
      openDayInvisalign{
        src{
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
